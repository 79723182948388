@charset "utf-8";

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター(section)
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#sect_footer{
	background-image: linear-gradient(45deg, #fff 40%, #d9edf8 40%, #d9edf8 50%, #fff 50%, #fff 90%, #d9edf8 90%, #d9edf8);
	    background-size: 10px 10px;
	    padding:50px 0;
	.inner_content{
		background-color:white;
		padding: 40px 30px 0;
		.block_title{
			font-size: 1.8rem;
			margin-bottom:20px;
		}
		.block_desc{
			font-size: 1.4rem;
			margin-bottom:30px;
		}
		.block_box{
			margin-bottom: 30px;
			.block_left{
				.item_title{
					border-bottom:solid 2px #0089d1;
					display:inline-block;
					font-size:1.6rem;
					margin-bottom:20px;
				    line-height: 1.6;
				}
				.item_desc{
					font-size:1.4rem;
					margin-bottom:20px;
				}
				.item_flow{
					margin-bottom:30px;
					.flow{
						background-color:#33b8d6;
						color:white;
						font-size:1.6rem;
						padding:10px;
						position:relative;
						text-align:center;
						small{
							display:block;
							font-size:1.3rem;
						}
						&+.flow{
							margin-top:8px;
							&:after{
								content:"";
								border-style: solid;
								border-width: 10px 6px 0 6px;
								border-color: #056599 transparent transparent transparent;
								height: 0;
							    margin: 0 auto;
							    position: absolute;
							    left: 0;
							    right: 0;
							    top: -4px;
								width: 0;
							}
						}
					}
				}
			}
			.block_right{
				display: flex;
				justify-content: space-between;
				.item_img{
					width:calc(100% / 2 - 5px);
				}
			}
		}
		.block_contact{
			background-color:#0089d1;
			margin-left: calc(((100vw - 100%) / 2) * -1);
			margin-right: calc(((100vw - 100%) / 2) * -1);
			padding: 30px calc((100vw - 100%) / 2);
			text-align:center;
			.item_desc{
				color:white;
				font-size:1.6rem;
			}
			.item_tel{
				display:flex;
			    align-items: baseline;
			    justify-content: center;
			    margin-bottom:20px;
			    .tel{
					color:white;
					font-size:2.4rem;
			    }
			    .num{
					color:white;
					font-size:3.4rem;
			    }
			    a{
			    	color:white;
			    }
			}
			.item_contact{
				a{
					background-color:white;
					color:#004aab;
					display:block;
					font-size:1.6rem;
					font-weight:600;
					height:48px;
					line-height:48px;
					margin:0 auto;
					width:220px;
				}
			}
		}
	}
}
@media only screen and (min-width: 767px) {
	#sect_footer{
		    padding:100px 0;
		.inner_content{
			border:solid 1px #0089d1;
			padding: 50px 50px 0;
			.block_title{
				font-size: 2.2rem;
			}
			.block_desc{
				font-size: 1.5rem;
				margin-bottom:50px;
			}
			.block_box{
				display:flex;
			    justify-content: space-between;
				margin-bottom: 50px;
				.block_left{
					width:calc(100% - 300px);
					.item_title{
						font-size:2rem;
						margin-bottom:30px;
					}
					.item_desc{
						font-size:1.5rem;
					}
					.item_flow{
						margin-bottom:0px;
						max-width:420px;
						.flow{
							padding:12px;
							small{
								display:inline-block;
								margin-left:10px;
							}
						}
					}
				}
				.block_right{
					display: block;
					margin-top: 65px;
					width:270px;
					.item_img{
						width:100%;
						&+.item_img{
							margin-top:20px;
						}
					}
				}
			}
			.block_contact{
				margin-left: -50px;
				margin-right: -50px;
				padding: 30px 50px 40px;
				text-align:center;
				.item_desc{
					font-size:1.8rem;
					margin-bottom: 25px;
				}
				.item_box{
					display:flex;
					justify-content: center;
				}
				.item_tel{
					margin-right:60px;
				    margin-bottom:0px;
				    .tel{
				    }
				    .num{
				    }
				}
				.item_contact{
					a{

					}
				}
			}
		}
	}

}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター
–––––––––––––––––––––––––––––––––––––––––––––––––– */
footer{
	.wrap_footer{
		background-color:white;
		.inner_box{
			padding:40px 30px;
			.inner_logo{
				margin-bottom: 20px;
				.block_logo{
					.item_logo{
						display:block;
						margin:0 auto;
						width:150px;
						img{
							vertical-align: middle;
						}
					}
				}
			}
			.inner_address{
				text-align:center;
				font-size:1.4rem;
				.block_zip{
				}
				.block_contact{
					margin-bottom: 25px;
				}
				.block_cat{
					font-size:1.2rem;
					.item_cat{
					    line-height: 1.8;
						&+.item_cat{
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 767px) {
	footer{
			background-color:white;
		.wrap_menu{
			background:#00125d;
			.inner_menu{
				margin: 0 auto;
				max-width: 1080px;
				padding: 8px 20px;
				ul{
					display:flex;
					flex-flow:wrap row;
				    justify-content: center;
					li{
						a{
							color:white;
							display:block;
							font-size:1.4rem;
						    padding: 5px 30px 5px 0;
						}
					}
				}
			}
		}
		.wrap_footer{
			.inner_box{
				.inner_logo{
					margin-bottom:20px;
					.block_logo{
						.item_logo{
							display:block;
							margin:0 auto;
							img{
								height:30px;
								width:250px;
							}
						}
					}
				}
				.inner_address{
					.block_zip{
					}
					.block_contact{
					}
					.block_cat{
						font-size:1.3rem;
						.item_cat{
							&+.item_cat{
								margin-top: 0px;
							}
						}
					}
				}
			}
		}
	}
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
コピーライト、ページトップへ戻る
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#copyright{
	background-color: #222222;
	padding: 8px;
	text-align:center;
	a{
		color: white;
		display:block;
		font-size:1.2rem;
	}
}
#js_topBtn{
	display:none;
	position:fixed;
	bottom:50px;
	right:0;
	transition: all 0.3s ease;
	z-index: 98;
	.item_btn{
		background-color:#222222;
		display:block;
		height:48px;
		position:relative;
		width:48px;
		.icon{
			color:white;
			display: block;
			text-align: center;
			line-height: 30px;
		    img{
		    	height:11px;
				position: absolute;
			 	top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
			 	-ms-transform: translate(-50%, -50%);
			 	transform: translate(-50%, -50%);
		    	width:20px;
		    }
	   	}
	}
}
@media only screen and (min-width: 767px) {
	#copyright{
		padding: 10px;
		a{
		}
	}
	#js_topBtn{
		.btn{
			.icon{
		   	}
		}
	}
}