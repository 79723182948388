@charset "UTF-8";
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター(section)
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#sect_footer {
  background-image: linear-gradient(45deg, #fff 40%, #d9edf8 40%, #d9edf8 50%, #fff 50%, #fff 90%, #d9edf8 90%, #d9edf8);
  background-size: 10px 10px;
  padding: 50px 0; }
  #sect_footer .inner_content {
    background-color: white;
    padding: 40px 30px 0; }
    #sect_footer .inner_content .block_title {
      font-size: 1.8rem;
      margin-bottom: 20px; }
    #sect_footer .inner_content .block_desc {
      font-size: 1.4rem;
      margin-bottom: 30px; }
    #sect_footer .inner_content .block_box {
      margin-bottom: 30px; }
      #sect_footer .inner_content .block_box .block_left .item_title {
        border-bottom: solid 2px #0089d1;
        display: inline-block;
        font-size: 1.6rem;
        margin-bottom: 20px;
        line-height: 1.6; }
      #sect_footer .inner_content .block_box .block_left .item_desc {
        font-size: 1.4rem;
        margin-bottom: 20px; }
      #sect_footer .inner_content .block_box .block_left .item_flow {
        margin-bottom: 30px; }
        #sect_footer .inner_content .block_box .block_left .item_flow .flow {
          background-color: #33b8d6;
          color: white;
          font-size: 1.6rem;
          padding: 10px;
          position: relative;
          text-align: center; }
          #sect_footer .inner_content .block_box .block_left .item_flow .flow small {
            display: block;
            font-size: 1.3rem; }
          #sect_footer .inner_content .block_box .block_left .item_flow .flow + .flow {
            margin-top: 8px; }
            #sect_footer .inner_content .block_box .block_left .item_flow .flow + .flow:after {
              content: "";
              border-style: solid;
              border-width: 10px 6px 0 6px;
              border-color: #056599 transparent transparent transparent;
              height: 0;
              margin: 0 auto;
              position: absolute;
              left: 0;
              right: 0;
              top: -4px;
              width: 0; }
      #sect_footer .inner_content .block_box .block_right {
        display: flex;
        justify-content: space-between; }
        #sect_footer .inner_content .block_box .block_right .item_img {
          width: calc(100% / 2 - 5px); }
    #sect_footer .inner_content .block_contact {
      background-color: #0089d1;
      margin-left: calc(((100vw - 100%) / 2) * -1);
      margin-right: calc(((100vw - 100%) / 2) * -1);
      padding: 30px calc((100vw - 100%) / 2);
      text-align: center; }
      #sect_footer .inner_content .block_contact .item_desc {
        color: white;
        font-size: 1.6rem; }
      #sect_footer .inner_content .block_contact .item_tel {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-bottom: 20px; }
        #sect_footer .inner_content .block_contact .item_tel .tel {
          color: white;
          font-size: 2.4rem; }
        #sect_footer .inner_content .block_contact .item_tel .num {
          color: white;
          font-size: 3.4rem; }
        #sect_footer .inner_content .block_contact .item_tel a {
          color: white; }
      #sect_footer .inner_content .block_contact .item_contact a {
        background-color: white;
        color: #004aab;
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        height: 48px;
        line-height: 48px;
        margin: 0 auto;
        width: 220px; }

@media only screen and (min-width: 767px) {
  #sect_footer {
    padding: 100px 0; }
    #sect_footer .inner_content {
      border: solid 1px #0089d1;
      padding: 50px 50px 0; }
      #sect_footer .inner_content .block_title {
        font-size: 2.2rem; }
      #sect_footer .inner_content .block_desc {
        font-size: 1.5rem;
        margin-bottom: 50px; }
      #sect_footer .inner_content .block_box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px; }
        #sect_footer .inner_content .block_box .block_left {
          width: calc(100% - 300px); }
          #sect_footer .inner_content .block_box .block_left .item_title {
            font-size: 2rem;
            margin-bottom: 30px; }
          #sect_footer .inner_content .block_box .block_left .item_desc {
            font-size: 1.5rem; }
          #sect_footer .inner_content .block_box .block_left .item_flow {
            margin-bottom: 0px;
            max-width: 420px; }
            #sect_footer .inner_content .block_box .block_left .item_flow .flow {
              padding: 12px; }
              #sect_footer .inner_content .block_box .block_left .item_flow .flow small {
                display: inline-block;
                margin-left: 10px; }
        #sect_footer .inner_content .block_box .block_right {
          display: block;
          margin-top: 65px;
          width: 270px; }
          #sect_footer .inner_content .block_box .block_right .item_img {
            width: 100%; }
            #sect_footer .inner_content .block_box .block_right .item_img + .item_img {
              margin-top: 20px; }
      #sect_footer .inner_content .block_contact {
        margin-left: -50px;
        margin-right: -50px;
        padding: 30px 50px 40px;
        text-align: center; }
        #sect_footer .inner_content .block_contact .item_desc {
          font-size: 1.8rem;
          margin-bottom: 25px; }
        #sect_footer .inner_content .block_contact .item_box {
          display: flex;
          justify-content: center; }
        #sect_footer .inner_content .block_contact .item_tel {
          margin-right: 60px;
          margin-bottom: 0px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター
–––––––––––––––––––––––––––––––––––––––––––––––––– */
footer .wrap_footer {
  background-color: white; }
  footer .wrap_footer .inner_box {
    padding: 40px 30px; }
    footer .wrap_footer .inner_box .inner_logo {
      margin-bottom: 20px; }
      footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo {
        display: block;
        margin: 0 auto;
        width: 150px; }
        footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo img {
          vertical-align: middle; }
    footer .wrap_footer .inner_box .inner_address {
      text-align: center;
      font-size: 1.4rem; }
      footer .wrap_footer .inner_box .inner_address .block_contact {
        margin-bottom: 25px; }
      footer .wrap_footer .inner_box .inner_address .block_cat {
        font-size: 1.2rem; }
        footer .wrap_footer .inner_box .inner_address .block_cat .item_cat {
          line-height: 1.8; }
          footer .wrap_footer .inner_box .inner_address .block_cat .item_cat + .item_cat {
            margin-top: 15px; }

@media only screen and (min-width: 767px) {
  footer {
    background-color: white; }
    footer .wrap_menu {
      background: #00125d; }
      footer .wrap_menu .inner_menu {
        margin: 0 auto;
        max-width: 1080px;
        padding: 8px 20px; }
        footer .wrap_menu .inner_menu ul {
          display: flex;
          flex-flow: wrap row;
          justify-content: center; }
          footer .wrap_menu .inner_menu ul li a {
            color: white;
            display: block;
            font-size: 1.4rem;
            padding: 5px 30px 5px 0; }
    footer .wrap_footer .inner_box .inner_logo {
      margin-bottom: 20px; }
      footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo {
        display: block;
        margin: 0 auto; }
        footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo img {
          height: 30px;
          width: 250px; }
    footer .wrap_footer .inner_box .inner_address .block_cat {
      font-size: 1.3rem; }
      footer .wrap_footer .inner_box .inner_address .block_cat .item_cat + .item_cat {
        margin-top: 0px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
コピーライト、ページトップへ戻る
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#copyright {
  background-color: #222222;
  padding: 8px;
  text-align: center; }
  #copyright a {
    color: white;
    display: block;
    font-size: 1.2rem; }

#js_topBtn {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 0;
  transition: all 0.3s ease;
  z-index: 98; }
  #js_topBtn .item_btn {
    background-color: #222222;
    display: block;
    height: 48px;
    position: relative;
    width: 48px; }
    #js_topBtn .item_btn .icon {
      color: white;
      display: block;
      text-align: center;
      line-height: 30px; }
      #js_topBtn .item_btn .icon img {
        height: 11px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 20px; }

@media only screen and (min-width: 767px) {
  #copyright {
    padding: 10px; } }
